<template>
    <VueFilePond name="file" ref="pond" label-idle="Drag and drop or click here to upload a screenshot or PDF..." credits="false" v-bind:allow-multiple="false" accepted-file-types="image/jpeg, image/png, application/pdf" @processfile="processFile" />
    <!--  
        @removefile="removeFile"
        @click.native="$emit('focus', $event)" 
        :files="myFiles"  
        :server="imageUploadServer" -->
</template>

<script>
import VueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import VueCookies from "vue-cookies";

// import { EventBus } from "@/main.js";

export default {
    components: {
        VueFilePond: VueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginPdfPreview),
    },
    methods: {
        processFile(error, file) {
            if (!error) {
                this.screenshot.available = true;
                this.screenshot["location"] = JSON.parse(file.serverId).file;
                this.screenshot["file_name"] = file.filename;
                this.screenshot["file_mime_type"] = file.fileType;
                this.screenshot["size"] = file.fileSize;
                this.valueSync = this.screenshot;
                this.$set(this.valueSync, this.screenshot);
            }
        },
    },
    data() {
        return {
            imageUploadServer: {
                process: {
                    url: `${process.env.VUE_APP_BLACK_BOX_URL}/api/v1/uploads/reports/images`,
                    method: "POST",
                    headers: {
                        Authorization: "",
                    },
                },
                load: this.loadScreenshot,
            },
            openImageURL: "",
            myFiles: [],
            screenshot: {},
        };
    },
    mounted() {
        const token = VueCookies.get("token");
        this.imageUploadServer.process.headers["Authorization"] = `Bearer ${token}`;
        if (this.value && this.value.available) {
            this.screenshot = this.value;
            this.myFiles = [{ source: this.screenshot.location, options: { type: "local" } }];
        }
        //  ? (this.screenshot = this.value) : (this.screenshot = { available: false });
    },
};
</script>

<style>
</style>